<template>
  <moe-page title="查看N元任选">
    <moe-card :autoHeight="true" class="mb-20">
      <moe-form :showBack="false">
        <moe-describe-list title="基本信息" :col="1">
          <moe-describe-item label="活动名称"> {{ nYuanOptionalParams.name }} </moe-describe-item>
          <moe-describe-item label="活动时间">{{ nYuanOptionalParams.startTime }} 至 {{ nYuanOptionalParams.endTime }} </moe-describe-item>
          <moe-describe-item label="活动封面">
            <moe-image
              :src="nYuanOptionalParams.cover"
              width="100px"
              height="100px"
            />
          </moe-describe-item>
        </moe-describe-list>

        <moe-describe-list title="活动内容" :col="1">
          <moe-describe-item label=""> {{ `${nYuanOptionalParams.money} 元 任选 ${nYuanOptionalParams.number} 件` }} </moe-describe-item>
        </moe-describe-list>
      </moe-form>

      <div class="fwb mb-20 font-20">商品范围</div>

      <div style="height: 700px;min-height: 700px;" class="df fdc">
        <moe-table
          :stripe="true"
          :data="nYuanOptionalParams.configList"
          :mode="false"
          :showPage="false"
          :number-show="false"
          :params="{ pageNum: 1 }"
          emptyText="请添加商品"
          rowKey="goodsId">
          <el-table-column label="商品信息" min-width="200">
            <template slot-scope="{ row }">
              <div class="df aic">
                <moe-image :src="row.coverUrl" width="80px" height="80px" />
                <div class="ml-10 df fdc aifs">
                  <p class="fwb tal">{{ row.name }}</p>
                  <p class="font-12 color-info">商品ID: {{ row.goodsId }}</p>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="销售价" min-width="150">
            <template slot-scope="{ row }">
              {{ `¥ ${row.minPrice}` }}{{ $moe_math.mathGreaterThan(row.maxPrice, row.minPrice) ? ` - ¥ ${row.maxPrice}` : '' }}
            </template>
          </el-table-column>

          <el-table-column label="库存" prop="stock" min-width="80" />
        </moe-table>
      </div>

      <moe-form :showBack="false">
        <template slot="tool">
          <el-button icon="el-icon-back" @click="$moe_coordinator.navigateBack()">返回列表</el-button>
        </template>
      </moe-form>
    </moe-card>
  </moe-page>
</template>

<script>
export default {
  name: 'ActivityManageNYuanOptionalDetail',
  data() {
    return {
      nYuanOptionalParams: {
        id: '',
        name: '',
        startTime: '',
        endTime: '',
        cover: '',
        money: '',
        number: '',
        configList: [],
      },
    }
  },
  methods: {
    /** 根据商品ID获取商品列表 */
    getQueryShopGoodsByIds(goodsList) {
      this.$moe_api.GOODS_API.getQueryShopGoodsByIds({ ids: goodsList.map(({ goodsId }) => goodsId).join(',') }).then((data) => {
        if (data.code === 200) {
          this.nYuanOptionalParams.configList = goodsList.map(({ goodsId, id }) => {
            let findGoodsItem = data.result.find(({ id }) => goodsId === id);
            return {
              ...findGoodsItem,
              goodsId: findGoodsItem.id,
              id,
            }
          })
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 获取N元任选详情 */
    req_getOptionalDetail() {
      if (this.$route.query.id) {
        this.$moe_api.NYUANOPTIONAL_API.getOptionalDetail({ id: this.$route.query.id }).then((data) => {
          if (data.code === 200) {
            let { id, name, startTime, endTime, cover, money, number, configList } = data.result;
            this.nYuanOptionalParams = {
              id,
              name,
              startTime,
              endTime,
              cover,
              money,
              number,
              configList: [],
            }

            if (configList.length) {
              this.getQueryShopGoodsByIds(configList);
            }

          } else {
            this.$moe_msg.error(data.message);
          }
        })
      }
    }
  },
  mounted() {
    this.req_getOptionalDetail();
  }
}
</script>

<style lang="scss">

</style>